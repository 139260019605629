<template>
    <div class="">
      <div class="row">
        <div class="col-6">
          <h3>{{label}}</h3>
          <div v-if="imageUrl" class="mb-3">
            <img :src="imageUrl" alt="Profile Image" class="img-thumbnail" style="max-width: 200px;">
          </div>
          <div v-else>
            <p>No image uploaded</p>
          </div>
          <div class="button-group">
            <button type="button" class="btn btn-info mr-2 mb-2" @click="showImageUploadModal = true">Upload New Image</button>
            <button type="button" class="btn btn-secondary mr-2 mb-2" @click="openImageSelectionModal">Select Existing Image</button>
            <button type="button" class="btn btn-danger mb-2" @click="deleteProfileImage" v-if="imageUrl">Delete Image</button>
          </div>
        </div>
      </div>
      <!-- New section for user descriptions -->


      <!-- Image Upload Modal -->
      <div class="modal" tabindex="-1" role="dialog" v-if="showImageUploadModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Upload Profile Image</h5>
              <button type="button" class="close" @click="showImageUploadModal = false">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input type="file" class="form-control-file" id="imageUpload" @change="handleFileChange" accept="image/*">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="showImageUploadModal = false">Close</button>
              <button type="button" class="btn btn-primary" @click="uploadImage()" :disabled="!selectedFile">Upload</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Updated modal for image selection with styled close button -->
      <div class="modal" tabindex="-1" role="dialog" v-if="showImageSelectionModal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Select an Image</h5>
              <button type="button" class="close" @click="showImageSelectionModal = false" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="image-grid">
                <div v-for="image in availableImages" :key="image.id" class="image-item" @click="selectImage(image)">
                  <img :src="image.url" :alt="image.name" class="img-thumbnail">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="showImageSelectionModal = false">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const api = window.api;
export default {
  name: 'ImageEdit',
  props: {
    modelValue: String,
    label: String
  },
  data() {
    return {
      imageUrl: null,
      showImageUploadModal: false,
      selectedFile: null,
      showImageSelectionModal: false,
      availableImages: [],
      currentUserId: api.currentUser.id,
    };
  },
  mounted() {
    this.imageUrl = this.modelValue
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.selectedFile) {
        return;
      }
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      try {
        const response = await api.post('/api/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // Assuming the API returns the image URL
        this.imageUrl = response.url;
        this.showImageUploadModal = false;
        this.selectedFile = null;
        this.updateRecord()
        // Show a success message
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
      }
    },
    deleteProfileImage() {
        this.imageUrl = null;
        this.updateRecord()
    },
    async openImageSelectionModal() {
      try {
        const response = await api.get('/api/list-images')
        this.availableImages = response;
        this.showImageSelectionModal = true;
      } catch (error) {
        console.error('Error fetching images:', error)
        // Handle error (e.g., show an error message to the user)
      }
    },
    selectImage(image) {
      this.imageUrl = image.url
      this.showImageSelectionModal = false
      this.updateRecord()
    },
    updateRecord() {
      this.$emit('update:modelValue', this.imageUrl);
    }
  },
}
</script>

<style scoped>

.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.image-item {
  cursor: pointer;
  transition: transform 0.2s;
}

.image-item:hover {
  transform: scale(1.05);
}

.image-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.btn {
  margin-right: 10px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

</style>