<template>
    <div class="language-edit form-material" v-if="record">
      <h2>{{ isNewRecord ? 'Create Record' : 'Edit Record' }}</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" class="form-control" id="name" v-model="record.Name" required>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="code">Code</label>
            <input type="text" class="form-control" id="code" v-model="record.Code" required>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="btn-group" role="group">
            <button type="submit" class="btn btn-primary" @click="saveRecord">Save</button>
            <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
            <button type="button" class="btn btn-danger" @click="deleteRecord" v-if="!isNewRecord">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import RecordMixin from '@/components/tools/RecordMixin.vue';
  export default {
    name: 'LanugageEdit',
    mixins: [RecordMixin],
    props: ['id'],
    data() {
      return {
        record: {
          Name: '',
          Code: ''
        },
        table: 'language',
      };
    },
  }
  </script>
  
  <style scoped>
  .language-edit {
    margin: 0 auto;
    padding: 20px;
  }
  </style>