<template>
    <div class="access-group-edit form-material" v-if="record">
      <h2>{{ isNewRecord ? 'Create' : 'Edit' }} Access Group</h2>
      <div>
        <div class="mb-3">
          <label for="description" class="form-label">Name (Description)</label>
          <input
            type="text"
            class="form-control"
            id="description"
            v-model="record.Description"
            required
          >
        </div>
  
        <div class="mb-3">
          <label class="form-label">Permissions</label>
          <JsonEditor v-model="record.Permissions" />
        </div>
  
        <div class="mt-3">
          <button type="submit" class="btn btn-primary" @click="saveRecord">Save</button>
          <button type="button" class="btn btn-secondary ms-2" @click="cancel">Cancel</button>
          <button v-if="!isNewRecord" type="button" class="btn btn-danger ms-2" @click="deleteRecord">
            Delete
          </button>
        </div>
      </div>
    </div>
  </template>
  
<script>
import JsonEditor from './JsonEditor.vue';
import RecordMixin from '@/components/tools/RecordMixin.vue';

export default {
    name: 'AccessGroupEdit',
    mixins: [RecordMixin],
    props: ['id'],
    components: {
        JsonEditor
    },
    data() {
      return {
        record: {
          id: null,
          Description: '',
          Permissions: {}
        },
        table: 'accessgroup',
      };
    },
  };
</script>
  
<style scoped>
.access-group-edit {
    margin: 0 auto;
    padding: 20px;
}
</style>