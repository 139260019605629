<template>
    <div class="user-edit form-material" v-if="record">
      <h2>{{ isNewRecord ? 'Create User' : 'Edit User' }}</h2>
      
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="userName">Username</label>
            <input type="text" class="form-control" id="userName" v-model="record.UserName" required>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="tourPlanUser">Tour Plan User</label>
            <input type="text" class="form-control" id="tourPlanUser" v-model="record.TourPlanUser">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" class="form-control" id="firstName" v-model="record.FirstName">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" v-model="record.LastName">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" v-model="record.Email">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="phone">Phone</label>
            <input type="tel" class="form-control" id="phone" v-model="record.Phone">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="calendarLink">Calendar Link</label>
            <input type="text" class="form-control" id="calendarLink" v-model="record.CalendarLink">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="accessGroup">Access Group</label>
            <select class="form-control" id="accessGroup" v-model="record.AccessGroupId">
              <option v-for="group in accessGroups" :key="group.id" :value="group.id">
                {{ group.Description }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" v-model="record.Password" :required="isNewRecord">
            <small class="form-text text-muted" v-if="!isNewRecord">Leave blank to keep current password</small>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="changePassword" v-model="record.ChangePassword">
            <label class="form-check-label" for="changePassword">Require password change</label>
          </div>
        </div>
      </div>
      <!-- Add this section for displaying the profile image -->
      <div class="row mt-4" v-if="record">
        <ImageEdit v-model="record.ProfileImageUrl" label="Profile Image" class="col-md-6" @updateRecord="record.ProfileImageUrl = $event" /> 
        <ImageEdit v-model="record.ProfileImageUrlBig" label="Profile Image Horizontal" class="col-md-6" @updateRecord="record.ProfileImageUrlBig = $event" />
      </div>
      <!-- New section for user descriptions -->
      <div class="row mt-4">
        <div class="col-12">
          <h3>User Descriptions</h3>
          <div v-for="(description, index) in record.UserProfiles" :key="index" class="mb-3">
            <div class="row">
              <div class="col-md-3">
                <select class="form-control" v-model="description.LanguageId">
                  <option v-for="language in languages" :key="language.id" :value="language.id">
                    {{ language.Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-8">
                <textarea class="form-control" v-model="description.Description" rows="2"></textarea>
              </div>
              <div class="col-md-1">
                <button type="button" class="btn btn-danger" @click="removeDescription(index)">X</button>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-secondary" @click="addDescription">Add Description</button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="btn-group" role="group">
            <button type="submit" class="btn btn-primary" @click="saveRecord">Save</button>
            <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
            <button 
              type="button" 
              class="btn btn-danger" 
              @click="deleteRecord" 
              v-if="!isNewRecord && record.id !== currentUserId"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const api = window.api;
import RecordMixin from '@/components/tools/RecordMixin.vue';
import ImageEdit from '@/components/pages/Image.vue';
export default {
  name: 'UserEdit',
  props: ['id'],
  mixins: [RecordMixin],
  components: {
    ImageEdit
  },
  data() {
    return {
      record: {
        UserName: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        AccessGroupId: 1,
        Password: '',
        ChangePassword: false,
        TourPlanUser: '',
        ProfileImageUrl: '',
        ProfileImageUrlBig: '',
      },
      table: 'user',
      accessGroups: [],
      languages: [],
      currentUserId: api.currentUser.id,
    };
  },
  async mounted () {
    this.fetchAccessGroups();
    this.fetchLanguages();
  },
  methods: {
    async fetchAccessGroups() {
      this.accessGroups = await api.get('/api/accessgroup/')
    },
    async fetchLanguages() {
      this.languages = await api.get('/api/language/')
    },
    addDescription() {
      if (!this.record.UserProfiles) {
        this.record.UserProfiles = [];
      }
      this.record.UserProfiles.push({ LanguageId: '', Description: '' });
    },
    removeDescription(index) {
      this.record.UserProfiles.splice(index, 1);
    },
  },
}
</script>

<style scoped>
.user-edit {
    margin: 0 auto;
  padding: 20px;
}

.btn {
  margin-right: 10px;
}
</style>