<template>
  <div class="booking-details" v-if="record">
    <h2>Booking Details</h2>

    <!-- Contact Information -->
    <section class="section">
      <h3>Contact Information</h3>
      <div class="details-container">
        <div class="detail-item">
          <span class="label">Name:</span>
          <span class="value">{{ booking.PaxName }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Address:</span>
          <span class="value">{{ booking.Address }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Zip Code:</span>
          <span class="value">{{ booking.ZipCode }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Nationality:</span>
          <span class="value">{{ booking.Nationality }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Mobile:</span>
          <span class="value">{{ booking.Mobile }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Email:</span>
          <span class="value">{{ booking.Email }}</span>
        </div>
      </div>
    </section>

    <!-- Passengers -->
    <section class="section" v-if="booking.BookingFormPaxs?.length">
      <h3>Passengers</h3>
      <div class="details-container" v-for="(pax, index) in booking.BookingFormPaxs" :key="index">
        <h4>Passenger {{ index + 1 }}</h4>
        <div class="detail-item">
          <span class="label">Name:</span>
          <span class="value">{{ pax.FirstName }} {{ pax.LastName }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Date of Birth:</span>
          <span class="value">{{ pax.DOB }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Gender:</span>
          <span class="value">{{ pax.Gender }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Passport:</span>
          <span class="value">{{ pax.Passport }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Trip Reason:</span>
          <span class="value">{{ pax.TripReason }}</span>
        </div>
        <div class="medical-info">
          <h5>Medical Information</h5>
          <div class="detail-item">
            <span class="label">Allergies:</span>
            <span class="value">{{ pax.Allergies || 'None' }}</span>
          </div>
          <div class="detail-item">
            <span class="label">Medical Conditions:</span>
            <span class="value">{{ pax.MedicalConditions || 'None' }}</span>
          </div>
          <div class="detail-item">
            <span class="label">Medication:</span>
            <span class="value">{{ pax.Medication || 'None' }}</span>
          </div>
          <div class="detail-item">
            <span class="label">Food Restrictions:</span>
            <span class="value">{{ pax.FoodRestriction || 'None' }}</span>
          </div>
        </div>
      </div>
    </section>

    <!-- Flights -->
    <section class="section" v-if="booking.BookingFormFlights?.length">
      <h3>Flight Information</h3>
      <div class="details-container" v-for="(flight, index) in booking.BookingFormFlights" :key="index">
        <h4>Flight {{ index + 1 }} ({{ flight.FlightType }})</h4>
        <div class="detail-item">
          <span class="label">Airline:</span>
          <span class="value">{{ flight.Airline }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Flight Number:</span>
          <span class="value">{{ flight.FlightNumber }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Reservation Code:</span>
          <span class="value">{{ flight.ReservationCode }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Departure:</span>
          <span class="value">{{ flight.DepartureCity }} - {{ formatDateTime(flight.DepartureDate, flight.DepartureTime) }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Arrival:</span>
          <span class="value">{{ flight.ArrivalCity }} - {{ formatDateTime(flight.ArrivalDate, flight.ArrivalTime) }}</span>
        </div>
      </div>
    </section>

    <!-- Rooms -->
    <section class="section" v-if="booking.BookingFormRooms?.length">
      <h3>Room Information</h3>
      <div class="details-container" v-for="(room, index) in booking.BookingFormRooms" :key="index">
        <h4>Room {{ index + 1 }}</h4>
        <div class="detail-item">
          <span class="label">Type:</span>
          <span class="value">{{ room.Type }}</span>
        </div>
        <div class="detail-item">
          <span class="label">Names:</span>
          <span class="value">{{ room.Names }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import RecordMixin from '@/components/tools/RecordMixin.vue';
  export default {
    name: 'BookingDetails',
    mixins: [RecordMixin],
    props: ['id'],
    data() {
      return {
        table: 'bookingform',
      };
    },
    computed: {
      booking() {
        return this.record;
      }
    },
    methods: {
      formatDateTime(date, time) {
        if (!date) return '';
        const formattedDate = new Date(date).toLocaleDateString();
        return time ? `${formattedDate} ${time}` : formattedDate;
      }
    }
  }
</script>

<style scoped>
.booking-details {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.section {
  margin-bottom: 30px;
}

.details-container {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.detail-item {
  margin-bottom: 12px;
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.label {
  font-weight: bold;
  min-width: 150px;
  color: #333;
}

.value {
  color: #666;
}

h3 {
  color: #2c3e50;
  margin-bottom: 15px;
}

h4 {
  color: #2c3e50;
  margin-bottom: 12px;
}

.medical-info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

h5 {
  color: #2c3e50;
  margin-bottom: 10px;
}
</style>
