<template>
    <div class="bookingform-list">
      <h2>Booking Form List</h2>
      <v-client-table
        :data="bookingforms"
        :columns="columns"
        :options="options"
        @row-click="showBookingForm"
      ></v-client-table>
    </div>
  </template>
  
  <script>
  const api = window.api;
  export default {
    name: 'BookingForms',
    data() {
      return {
        bookingforms: [],
        columns: ['id', 'PDCM', 'PaxName', 'Email'],
        options: {
          headings: {
            PDCM: 'PDCM',
            FirstName: 'First Name',
            LastName: 'Last Name',
          },
          sortable: ['PDCM', 'FirstName', 'LastName'],
          filterable: ['PDCM', 'FirstName', 'LastName'],
          perPage: 10,
          perPageValues: [10, 20, 50],
          skin: 'table table-striped table-hover',
        }
      };
    },
    mounted() {
      this.fetchBookingForms();
    },
    methods: {
      async fetchBookingForms() {
        this.bookingforms = await api.get('/api/bookingform/');
      },
      showBookingForm(data) {
        this.$router.push({name: 'bookingform', params: {id: data.row.id}})
      },
    }
  }
  </script>
  
  <style scoped>
  .bookingform-list {
    margin: 20px;
  }
  .mb-4 {
    margin-bottom: 16px;
  }
  </style>